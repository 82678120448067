<template>
  <div>
    <BasicLogo width="300" />
    <div class="content-title">
      <BasicTitle title="Términos y Condiciones" />
      <br />
      <div class="box-content">
        <BasicSubtitle
          :secondary="true"
          title="PRIMERO: Generalidades"
          subtitle="Los presentes términos y condiciones generales (en adelante, los “Términos y Condiciones”) regulan el acceso y uso de los servicios ofrecidos por TuAppto, a los usuarios de: i/ su sitio web, www.tuappto.com y/o cualquier otro dominio de TuAppto, (ambos conjuntamente en adelante denominados, el “Sitio Web”); y/o, ii/ la aplicación conocida como “TuAppto, disponible para sistemas iOS, y/o Android (en adelante, las “Aplicaciones Móviles”). Los Presentes Términos y Condiciones regularán cualquier contenido o comunicado emitido en el Sitio Web o en las Aplicaciones Móviles."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En estos Términos y Condiciones se fijan los derechos y obligaciones de las personas, ya sea, naturales o jurídicas, que hagan uso de manera habitual o no, de los servicios ofrecidos por TuAppto (en adelante, el “Usuario” o conjuntamente, en plural, los “Usuarios”)."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Los servicios prestados por TuAppto consisten en facilitar el acceso y uso a una plataforma tecnológica para la administración de edificios y condominios, por medio de la cual pueden interconectarse los administradores (los “Administradores”) con los residentes que habitan las unidades que componen los edificios (los “residentes”); ofreciendo a su vez herramientas para facilitar la administración, y gestión de todos los servicios necesarios y propios del rubro, por medio de la digitalización de los distintos aspectos que implica la vida en comunidad, tales como la administración de porterías (incluyendo gestión de bitácoras, encomiendas, visitas, reportes, áreas comunes, reservas, cámaras de seguridad, depósito de bicicletas y murales de avisos), de los departamentos, la contabilidad, los gastos comunes (su gestión y pago por medios digitales, las remuneraciones de los empleados, y otros servicios administrativos (tales como el reparto de documentos, el listado de proveedores de la comunidad, la entrega de noticias, la realización de votaciones entre los residentes, mantenciones de los espacios y equipamientos comunes, o una plataforma para el envío de correos masivos a todos los residentes alojados en el edificio). Adicionalmente, según sea aplicable, TuAppto por medio de su plataforma podrá conectar a los Usuarios con terceros proveedores de servicios (en adelante, los “Proveedores”), para el otorgamiento de servicios conexos a la plataforma (todos los anteriores, los “Servicios”). De esta manera, los servicios ofrecidos por TuAppto tienen únicamente la finalidad de facilitar el acceso a la plataforma tecnológica descrita, dejando la administración de esta a los Administradores, quienes a su vez harán uso de esta en conjunto con los residentes como Usuarios."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Una vez aceptados, estos Términos y Condiciones entrarán en plena vigencia y regularán de esta manera todos los actos, servicios, acuerdos y contratos que se ejecuten y se celebren a través de esta plataforma, y en general la relación, de cualquier clase que esta sea, entre TuAppto, los Usuarios y los Proveedores. Lo mismo ocurrirá con cualquier modificación que estos Términos y Condiciones puedan sufrir en el futuro. Al aceptar estos Términos y Condiciones el Usuario declara entender que TuAppto no asume otras obligaciones o responsabilidades que las que corresponden a un mero facilitador, que pone a disposición de los Usuarios y Proveedores una plataforma que opera a través del Sitio Web y Aplicaciones Móviles, por medio de la cual interactúan directamente los Usuarios (Administradores y Vecinos) y los Proveedores (en adelante, la “Plataforma”), y que en ningún caso y en ninguna circunstancia se entiende que TuAppto otorga otro tipo de servicios distinto a los descritos en el párrafo anterior, o subcontrata los servicios de los Proveedores, o se dedica a la administración de edificios y comunidades."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Cualquier persona que no acepte estos Términos y Condiciones, los que tienen un carácter obligatorio y vinculante, deberá abstenerse de utilizar el Sitio Web y la Aplicación Móvil, así como cualquier otro servicio o producto ofrecido por TuAppto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Se recomienda a los Usuarios leer detenidamente y entender estos Términos y Condiciones, como también imprimir o guardar una copia de estos para su información y respaldo."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="SEGUNDO: Acceso y Registro."
          subtitle="Cada persona que ingrese al Sitio Web y/o Aplicaciones Móviles será responsable de realizar todas las gestiones necesarias para tener acceso al mismo. El solo hecho de visitar el Sitio Web y/o Aplicaciones Móviles no generará el nacimiento de ninguna obligación para el Usuario ni para terceros, a menos que se exprese de manera inequívoca y por actos positivos exteriorizados, su voluntad de operar y hacer uso de los Servicios."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Es por lo anterior, que se entenderán aceptados los Términos y Condiciones una vez que el Usuario se encuentre registrado en el Sitio Web y/o Aplicaciones Móviles operado por TuAppto, hecho que se traduce en la aceptación expresa de los Términos y Condiciones. Todo Usuario que desee utilizar el Servicio deberá registrarse en el Sitio Web y/o Aplicaciones Móviles como se detalla más adelante, lo que implica la aceptación expresa de los presentes Términos y Condiciones."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="La cuenta de cada Usuario es personal, única e intransferible, se prohíbe que un mismo Usuario registre o posea más de una cuenta, así como también cualquier tipo de cesión o transferencia de cuentas a terceros."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Para llevar a cabo el enrolamiento y registro en el Sitio Web y/o Aplicaciones Móviles será obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, completa y verdadera de cada Usuario a través de los Administradores (en adelante, los “Datos del Usuario”)."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Esta información podrá ser provista por los Usuarios directamente o por un Administrador. Si la información es provista por un Administrador está podrá posteriormente ser complementada o modificada directamente por cada Usuario. Será responsabilidad de los Administradores contar con la autorización correspondiente para compartir la información de los Usuarios con TuAppto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Los Datos del Usuario compartidos por el Usuario directamente o por el Administrador, corresponderá a la información mínima que TuAppto solicita a los Usuarios para llevar adelante el otorgamiento de los Servicios. El Usuario se obliga en actualizar los Datos del Usuario tan pronto la modifique cuando haga uso del Sitio Web y/o de las Aplicaciones Móviles. Los Usuarios y/o el Administrador que provea los Datos del Usuario, garantizan la veracidad de los Datos del Usuario entregados en el formulario de registro, y serán responsables ante cualquier falta de veracidad, inexactitud o autenticidad de estos. Toda la información y los Datos del Usuario ingresados por el Usuario y/o el Administrador tienen el carácter de declaración jurada. El uso del Sitio Web y de las Aplicaciones Móviles y por tanto de los Servicios prestados por medio de TuAppto se encuentra prohibido para menores de 18 años."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Una vez registrado, el Usuario podrá acceder al Sitio Web y/o Aplicaciones Móviles, a la Plataforma, y a los servicios a ellos asociados y operados por TuAppto, por medio de la utilización de las credenciales registradas. TuAppto autorizará su registro a través de un correo electrónico enviado al Usuario."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto se reserva el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información entregada por un Usuario y/o un Administrador en materia de Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o definitivamente una cuenta, en caso de detectar incongruencias o inconsistencias en la información proveída por un Usuario o en caso de detectar actividades sospechosas, sin que tal decisión genere para el Usuario derechos de indemnización o resarcimiento de ningún tipo."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="TERCERO: Sobre los Servicios y el uso de la Plataforma."
          subtitle="pone a disposición de sus Usuarios:"
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="1. Cargar, listar, editar y eliminar listas de unidades funcionales y subunidades asociadas a estas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="2. Cargar, listar, editar y eliminar listas de residentes y propietarios."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="3. Registrar, notificar, listar y editar recepciones de encomiendas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="4. Registrar, notificar, listar y editar recepciones de visitas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="5. Cargar, listar, editar y eliminar inventarios de activos y equipamiento de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="6. Cargar, listar, editar y eliminar mantenciones de activos y equipamientos de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="7. Cargar, listar, editar y eliminar listas de trabajadores de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="8. Calcular, generar respaldos y notificar liquidaciones de sueldo de los trabajadores de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="9. Registrar, respaldar, listar y editar ingresos en la contabilidad de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="10. Registrar, respaldar, listar y editar egresos en la contabilidad de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="11. Publicar, notificar y mostrar noticias, publicaciones en mural público y correos masivos."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="12. Publicar, notificar, mostrar y responder reclamos."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="13. Cargar, listar, editar, reservar y eliminar áreas comunes de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="14. Vincular, mostrar y eliminar streamings de cámaras de seguridad de la comunidad que cumplan con criterios técnicos."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="15. Cargar, listar, editar y eliminar registros en la bitácora de novedades de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="16. Calcular, deshacer, editar, eliminar, listar, respaldar y notificar gastos comunes de la comunidad, y su respectivo prorrateo para cada unidad funcional de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="17. Cursar, listar, editar y eliminar multas a las unidades funcionales de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="18. Registrar, listar, editar y eliminar condonaciones de deudas a las unidades funcionales de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="19. Conciliar cuentas contables, listar, editar y eliminar conciliaciones bancarias."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="20. Planificar, listar y ejecutar presupuestos contables."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="21. Pago online de gastos comunes."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="22. Registrar automáticamente, notificar y listar pagos online de gastos comunes."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="23. Cargar, listar, editar y eliminar previsiones de gastos."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="24. Respaldar, descargar, listas, eliminar documentos de la comunidad."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="25. Crear y notificar votaciones electrónicas."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="26. Cargar, listar, editar y eliminar listas de proveedores."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="27. Configurar perfiles y niveles de acceso a la información de la plataforma."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto procurará evitar cualquier error en los contenidos que pudieran aparecer en el Sitio Web y/o Aplicaciones Móviles. Sin embargo, TuAppto no garantiza, representa, ni respalda o asume responsabilidad alguna en relación a la confiabilidad, exactitud, integridad o veracidad del material publicado por los Proveedores, sea o no a través de la Plataforma."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="CUARTO: Proveedores."
          subtitle="Se entenderán como Proveedores aquellas personas naturales o jurídicas que son presentadas por TuAppto dentro de un catálogo administrado por ésta y que se comunica a los residentes en interfaces o menús de diversa naturaleza por medio de la Plataforma, y por los distintos medios electrónicos que TuAppto estime pertinentes para dicho efecto. El orden, formato, priorización, posicionamiento, relevancia, oportunidad, tipografía, coloración u otra característica que la comunicación de los Proveedores o sus respectivos servicios tenga en la Plataforma de TuAppto no implica una preferencia, recomendación o sugerencia de TuAppto para que los Usuarios contraten uno u otro servicio. Será responsabilidad exclusiva de los residentes estudiar acabadamente la oferta de productos y servicios realizada por cada Proveedor, y compararlas adecuadamente antes de tomar una decisión de contratación. Los Proveedores mantienen relaciones contractuales con TuAppto, la cuales regulan los servicios que los Proveedores ofrecen en la Plataforma, así como su interacción con los Residentes. Dichos servicios se encuentran estrictamente relacionados al otorgamiento de servicios domésticos y de aseo y ornato, y en general, a cualquier otro servicio relacionado con el rubro inmobiliario."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Los Proveedores se han obligado contractualmente con TuAppto a respetar tanto la información entregada a través de la Plataforma operada por TuAppto en el Sitio Web y las Aplicaciones Móviles, como también las condiciones de las ofertas de servicios que se realicen por medio de la misma. Sin perjuicio de lo anterior, será completa responsabilidad de los Proveedores cumplir con la veracidad, autenticidad, exactitud y disponibilidad de los bienes y servicios ofrecidos por medio de la Plataforma y las Aplicaciones Móviles; eximiendo el Usuario a TuAppto, en forma expresa e irrevocable por la sola aceptación de estos Términos y Condiciones de cualquier responsabilidad que pudiese tener su origen en un incumplimiento de un Proveedor."
        />
        <BasicSubtitle
          :secondary="true"
          title="Quinto: Delimitación del negocio de TuAppto y declaración de conocimiento por parte del Usuario."
          subtitle="El Usuario declara y reconoce que la prestación de TuAppto consiste única y exclusivamente en otorgar una plataforma para la administración de comunidades de edificios y complejos, facilitando la conexión entre los Usuarios y otros terceros como los Proveedores. Adicionalmente, TuAppto podrá actuar de mandatario con el fin de procesar pagos realizados por medio de la Plataforma. De esta manera, la prestación ofrecida por TuAppto tiene únicamente la finalidad de facilitar el acceso y uso de la Plataforma. En consecuencia, el Usuario entiende que todo acto o contrato que se celebre a través de la Plataforma, así como el contenido, el uso y administración de la Plataforma, es ajeno al servicio que presta TuAppto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Por lo anterior, el Usuario declara y reconoce que el contenido de la Plataforma, su administración, y uso, no son responsabilidad de TuAppto, quienes tan solo la ponen a disposición de los Usuarios, y eventualmente a los Proveedores."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="El Usuario reconoce que TuAppto no es responsable por daños o perjuicios, directos o indirectos, proveniente de o en relación con: (a) el uso del Sitio Web y/o Aplicaciones Móviles; (b) en relación con el rendimiento o navegación en el Sitio Web y/o Aplicaciones Móviles o sus enlaces a otros sitios web; (c) la veracidad y exactitud de los datos proporcionados por los Proveedores o Administradores; (d) la calidad del servicio y/o bienes ofrecidos y prestados por los Proveedores; o (e) la información errónea, incompleta o incorrecta sobre los productos y servicios ofrecidos por los Proveedores."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto no garantiza que el Sitio Web y/o Aplicaciones Móviles funcionarán sin errores, ni que su contenido está o estará libre de virus, malware o software que pueda ser inyectado por terceros sin su consentimiento y/o conocimiento. TuAppto no será responsable de ningún daño (incluyendo necesidad de servicio técnico, cambios de equipos y/o pérdida de datos), como resultado del uso del Sitio Web y/o Aplicaciones Móviles."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="Sexto: Responsabilidad sobre la Información Registrada en el Sitio Web y/o Aplicaciones Móviles."
          subtitle="TuAppto adoptará las medidas necesarias y prudentes para resguardar la seguridad de los datos, mediante sistemas de encriptación de información, certificados de seguridad u otros que la empresa estime pertinente. TuAppto no manipula ni almacena datos financieros de sus usuarios, distintos de aquellos solicitados por los Proveedores para el otorgamiento de los Servicios. Sin perjuicio de ello, dichos datos son solamente almacenados a modo de respaldo, no siendo procesados directa ni indirectamente por TuAppto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="En caso de detectarse cambios en la información que el Usuario haya registrado en el Sitio Web y/o Aplicaciones Móviles, o bien ante cualquier irregularidad en las transacciones, identificación del Usuario o los medios de pago utilizados, así como también como medida de protección de los datos personales ingresados, los ejecutivos de TuAppto podrán contactar a los Usuarios por vía telefónica o correo electrónico, a fin de corroborar sus datos y/o movimientos e intentar evitar posibles fraudes."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Sin embargo, los Usuarios son exclusivamente responsables por la pérdida, mal uso o uso no autorizado de sus claves, códigos de validación, datos y demás antecedentes proporcionados en el Sitio Web y/o Aplicaciones Móviles, ya sea por parte de los mismos o de terceros; salvo que se acredite que dicha situación haya ocurrido por hecho o culpa grave de TuAppto."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="SEPTIMO: Datos Personales"
          subtitle="Los Usuarios garantizan que la información que suministran en el Sitio Web y/o Aplicaciones Móviles y demás plataformas de TuAppto es veraz, completa, exacta y actualizada."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="De conformidad con la Ley N° 18331, los datos personales que dichas personas suministren, pasarán a formar parte de una base de datos de propiedad y administrada por TuAppto de conformidad a lo establecido en la Política de Privacidad de TuAppto que el Usuario declara conocer, y serán destinados única y exclusivamente para ser utilizados en los fines que motivaron su entrega y especialmente para la comunicación en general entre la empresa, los Usuarios y los Proveedores, validar los datos de la compra, y responder consultas; sin perjuicio de la posibilidad de realizar eventuales estadísticas agregadas, para evaluar el comportamiento de los Usuarios y visitantes de su Sitio Web y/o Aplicaciones Móviles y demás plataformas. Del mismo modo, los datos no serán comunicados a otras empresas sin la expresa autorización de su titular."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto jamás solicitará datos personales o financieros a través de correo electrónico."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="TuAppto presume que los datos han sido incorporados por su titular o por persona autorizada por éste, así como también que éstos son correctos, actualizados y exactos, mientras sus titulares no los modifiquen. Los Usuarios, con la aceptación de los presentes Términos y Condiciones, manifiestan su aceptación a la Política de Privacidad de TuAppto."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Sin perjuicio de lo anterior, TuAppto garantiza a los Usuarios y demás visitantes al Sitio Web y/o Aplicaciones Móviles el libre ejercicio de sus derechos de información, modificación, cancelación y bloqueo de sus datos personales, establecidos en la Ley N° 18331. Por consiguiente, podrán realizar requerimientos que digan relación con dichos derechos, y en un plazo máximo de dos días hábiles, TuAppto deberá dar respuesta y/o implementar efectivamente la respectiva solicitud."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="OCTAVO: Propiedad Intelectual."
          subtitle="Todos los contenidos incluidos en el Sitio Web y/o Aplicaciones Móviles, como textos, material gráfico, logotipos, íconos de botones, códigos fuente, imágenes, audio clips, descargas digitales y compilaciones de datos, son propiedad de TuAppto o de sus proveedores de contenidos, y están protegidos por las leyes uruguayas e internacionales sobre propiedad intelectual. Los materiales gráficos, logotipos, encabezados de páginas, frases publicitarias, íconos de botones, textos escritos y nombres de servicios incluidos en el Sitio Web y/o Aplicaciones Móviles son marcas comerciales, creaciones o imágenes comerciales de propiedad de TuAppto. Dichas marcas, creaciones e imágenes comerciales no se pueden usar en relación a ningún producto o servicio que pueda causar confusión ante terceros y en ninguna forma que desprestigie o desacredite a TuAppto. Las demás marcas comerciales que no sean de propiedad de TuAppto y que aparezcan en este sitio pertenecen a sus respectivos propietarios."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Todos los derechos no expresamente otorgados en estos Términos y Condiciones son reservados por TuAppto o sus cesionarios, Proveedores, editores, titulares de derechos u otros proveedores de contenidos. Ningún producto, imagen o sonido pueden ser reproducidos, duplicados, copiados, vendidos, revendidos, visitados o explotados para ningún fin, en todo o en parte, sin el consentimiento escrito previo de TuAppto. No está permitido enmarcar o utilizar técnicas de enmarcación para encerrar alguna marca comercial, logotipo u otra información registrada o patentada (incluyendo imágenes, texto, disposición de páginas, o formulario) de TuAppto, sin el consentimiento escrito de ésta última. Tampoco se puede usar meta etiquetas ni ningún otro “texto oculto” que use el nombre o marcas comerciales de TuAppto, sin autorización escrita previa de esta empresa. Se prohíbe hacer un uso indebido del Sitio Web y/o Aplicaciones Móviles o de estas marcas, licencias o patentes. Lo anterior, sin perjuicio de las excepciones expresamente señaladas en la ley."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="NOVENO: Término de los Servicios."
          subtitle="TuAppto podrá poner término a los Servicios en cualquier momento, mediante un aviso con a lo menos 2 días de anticipación. Sin perjuicio de lo anterior, TuAppto podrá poner término anticipado a los Servicios, sin notificación o aviso y de forma inmediata, si el motivo de término anticipado tiene como causa la infracción por parte del Usuario, de los presentes Términos y Condiciones o, en su defecto, en situaciones de caso fortuito o fuerza mayor."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Por su parte el Usuario podrá eliminar su cuenta y así poner término a los servicios mediante el ingreso a la sección “Mi cuenta” en el Sitio Web y/o Aplicaciones Móviles, siguiendo las indicaciones ahí señaladas."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="DÉCIMO: Disposiciones Varias."
          subtitle="En caso de que alguna de las disposiciones de los presentes Términos y Condiciones fuere declarada judicialmente como no obligatoria, tal falta no afectará el cumplimiento del resto de las disposiciones. Si alguna disposición de los presentes Términos y Condiciones se considera inválida, inaplicable, nula por cualquier órgano administrativo o tribunal competente, o si, por ley, cualquier disposición se vuelve inválida, inaplicable, nula y sin efecto, las disposiciones restantes seguirán siendo válidas, en pleno vigor y efecto y las partes sustituirán la disposición inválida, nula o no ejecutable por otra válida y aplicable que se acerque lo más posible, al espíritu y finalidad de la norma sustituida. Si por algún motivo una corte o tribunal de jurisdicción competente o un árbitro determina que cualquier disposición de los presentes Términos y Condiciones, no es aplicable, o es nula o por cualquier causa, ineficaz, dicha disposición se aplicará hasta donde sea permitido y las demás condiciones de servicio continuarán vigentes."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="El retardo o no ejercicio de un derecho o remedio no se considerará como una renuncia."
        />
        <br />
        <BasicSubtitle
          :secondary="true"
          title="DÉCIMO PRIMERO: Ley Aplicable y Jurisdicción."
          subtitle="Los presentes Términos y Condiciones se regirán por las leyes de la República de Uruguay."
        />
        <BasicSubtitle
          :secondary="true"
          subtitle="Toda diferencia, controversia o conflicto, en relación con los presentes Términos y Condiciones o que por cualquier hecho relacionado o no con éstos, que surja entre el Usuario y TuAppto, así como sus partes relacionadas, se someterá a la jurisdicción exclusiva de los Tribunales Ordinarios de Justicia con asiento en la ciudad de Montevideo."
        />
      </div>
    </div>
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo";
import BasicTitle from "../../widgets/title/BasicTitle.vue";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle.vue";
import store from "../../store";
import { useRouter } from "vue-router";

export default {
  components: {
    BasicLogo,
    BasicTitle,
    BasicSubtitle,
  },
  setup() {
    const router = useRouter();

    return { store };
  },
};
</script>

<style scoped>
.content-title {
  margin-top: 4rem;
}
.box-content {
  padding: 0 10vw;
}
</style>
